import { Add, ArrowDropDown, Edit, Visibility } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Link,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import Container from 'components/atoms/Container';
import Item from 'components/atoms/Item';
import StatusChip from 'components/atoms/StatusChip';
import Config from 'config';
import { REMOTE } from 'constants/serverRoutes';
import { differenceInDays, parseISO } from 'date-fns';
import { dateFormatter } from 'helpers/dateChanger';
import { toFormattedCurrency } from 'helpers/stringHelper';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { makeRequest } from 'services';
import { useNotification } from 'services/hooks';
import S3DownloadLink from '../s3DownloadLink';

const PlatformMap = {
  [Config.GOINVEST_CLIENT_ID]: 'Go Invest',
  [Config.PLUANG_GROW_CLIENT_ID]: 'Pluang Grow',
  [Config.PLUANG_CLIENT_ID]: 'Pluang',
  [Config.PLUANG_WEB_CLIENT_ID]: 'Pluang Web'
};

const dateColumnNames = [
  'createdAt',
  'updatedAt',
  'created',
  'updated',
  'accountCreatedAt',
  'balanceMigratedAt',
  'dueDate',
  'datePaidOn',
  'expires',
  'validTo',
  'validFrom',
  'startTime',
  'endTime',
  'priceStatDate',
  'paymentDate',
  'startDate',
  'endDate'
];

const successStatus = [
  'active',
  'assigned',
  'paid',
  'success',
  'accepted',
  'completed',
  'claimed',
  'allocated',
  'successful',
  'done',
  'send',
  'delivered',
  'verified',
  'confirmed',
  'matched'
];

const PluangTable = (props) => {
  const { headers, roles, data, totalElements, totalPages, activePage, handlePageChange } = props;
  const { showNotification } = useNotification();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isAscending, setIsAscending] = useState(true);

  const removeSpacesFromString = (value) => {
    if (value) {
      return value.replace(/^ +/gm, '');
    }
    return null;
  };

  const sortTable = (data, header) => {
    let sortedData = data;
    sortedData.sort((a, b) => {
      if (!isNaN(a[header.db_column_name])) {
        if (isAscending) {
          setIsAscending(false);
          return a[header.db_column_name] - b[header.db_column_name];
        } else {
          setIsAscending(true);
          return b[header.db_column_name] - a[header.db_column_name];
        }
      } else if (isAscending) {
        setIsAscending(false);
        a[header.db_column_name] = removeSpacesFromString(a[header.db_column_name]);
        b[header.db_column_name] = removeSpacesFromString(b[header.db_column_name]);
        return a[header.db_column_name].localeCompare(b[header.db_column_name]);
      } else {
        setIsAscending(true);
        return b[header.db_column_name].localeCompare(a[header.db_column_name]);
      }
    });
  };

  const sendMail = (emailId, html, id) => {
    if (!emailId) navigate(-1);
    makeRequest({
      method: 'POST',
      url: `${REMOTE.VOUCHERS}/sendEmail`,
      params: {
        emailId,
        vouchersUrl: html,
        id
      },
      ignoreError: true
    }).then((result) => {
      if (result.data && result.status === 200) {
        showNotification('success', result.data.message);
      }
    });
  };

  const handleColor = (data) => {
    const createdDate = data?.created;
    const diff = differenceInDays(new Date(), parseISO(createdDate));
    const tableName = data && data.table;
    if (tableName && Config.ColorMap[tableName]) {
      return Config.ColorMap[tableName];
    }
    if (data?.todayCashout && data.todayCashout > 500000000 && diff < 1) {
      return '#F0DBFD';
    } else if (data?.todayCashout && data.todayCashout > 100000000 && diff < 1) {
      return '#FFA500';
    } else if (data?.weeklyCashout && data.weeklyCashout > 500000000 && diff < 7) {
      return '#FA426C';
    } else if (data?.weeklyCashout && data.weeklyCashout > 100000000 && diff < 7) {
      return '#FCFA79';
    } else {
      return 'white';
    }
  };

  const renderStatus = (label, status) => {
    if (successStatus.includes(status)) {
      return <StatusChip color="success" label={label} />;
    } else if (
      status === 'sending' ||
      status === 'approved' ||
      status === 'uploaded' ||
      status === 'redeemed' ||
      status === 'unassigned' ||
      status === 'started' ||
      status?.includes('submitted')
    ) {
      return <StatusChip color="primary" label={label} />;
    } else if (
      status === 'open' ||
      status === 'queued' ||
      status === 'unallocated' ||
      status === 'running' ||
      status?.includes('pending') ||
      status?.includes('process') ||
      status?.includes('progress')
    ) {
      return <StatusChip color="warning" label={label} />;
    } else if (
      status === 'amended' ||
      status === 'disbursed' ||
      status === 'requested' ||
      status === 'unpaid' ||
      status?.includes('ready') ||
      status?.includes('partial') ||
      status?.includes('waiting')
    ) {
      return <StatusChip color="info" label={label} />;
    } else if (
      !status ||
      status === 'n/a' ||
      status === 'refunded' ||
      status === 'suspended' ||
      status?.includes('expire')
    ) {
      return <StatusChip color="neutral" label={label || '-'} />;
    } else {
      return <StatusChip color="error" label={label} />;
    }
  };

  return (
    <Box>
      <TableContainer>
        <Table sx={{ whiteSpace: 'nowrap' }}>
          <TableHead>
            <TableRow>
              {headers
                .filter((header) => !header.role || roles?.indexOf(header.role) > -1)
                .map((header) => (
                  <TableCell key={header.db_column_name}>
                    {header.header_name}
                    {header.isSort ? (
                      <IconButton size="small" onClick={() => sortTable(data, header)}>
                        <ArrowDropDown />
                      </IconButton>
                    ) : null}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {totalElements > 0 &&
              data.map((row) => {
                const rowId = row.id || row._id || 'row_id';
                return (
                  <TableRow
                    key={rowId + (row.orderType ? row.orderType : '')}
                    sx={{ bgcolor: handleColor(row) }}>
                    {headers
                      .filter((header) => !header.role || roles.indexOf(header.role) > -1)
                      .map((header) => {
                        const { db_column_name, header_name } = header;
                        const formatAsStatus = header.formatAsStatus === 'false' ? false : true;
                        if (db_column_name === 'isVerified') {
                          return (
                            <TableCell key={rowId + '_' + db_column_name}>
                              {row[db_column_name] || row[db_column_name] === 'true' ? (
                                <StatusChip color="success" label="Verified" />
                              ) : (
                                <StatusChip color="error" label="Unverified" />
                              )}
                            </TableCell>
                          );
                        } else if (db_column_name?.includes('enable')) {
                          return (
                            <TableCell key={rowId + '_' + db_column_name}>
                              {row[db_column_name] || row[db_column_name] === 'true' ? (
                                <StatusChip color="success" label="Enabled" />
                              ) : (
                                <StatusChip color="error" label="Disabled" />
                              )}
                            </TableCell>
                          );
                        } else if (
                          db_column_name === 'isAnnounced' ||
                          db_column_name === 'matched'
                        ) {
                          return (
                            <TableCell key={rowId + '_' + db_column_name}>
                              {row[db_column_name] || row[db_column_name] === 'true' ? (
                                <StatusChip color="success" label="Yes" />
                              ) : (
                                <StatusChip color="error" label="No" />
                              )}
                            </TableCell>
                          );
                        } else if (db_column_name === 'pepCheck') {
                          return (
                            <TableCell key={rowId + '_active'}>
                              {row[db_column_name] || row[db_column_name] === true ? (
                                <StatusChip color="success" label="TRUE" />
                              ) : row[db_column_name] || row[db_column_name] === false ? (
                                <StatusChip color="error" label="FALSE" />
                              ) : (
                                <StatusChip color="grey" label="-" />
                              )}
                            </TableCell>
                          );
                        } else if (
                          db_column_name === 'active' ||
                          typeof row[db_column_name] === 'boolean'
                        ) {
                          return (
                            <TableCell key={rowId + '_active'}>
                              {row[db_column_name] || row[db_column_name] === 'true' ? (
                                <StatusChip color="success" label="Active" />
                              ) : (
                                <StatusChip color="error" label="Inactive" />
                              )}
                            </TableCell>
                          );
                        } else if (db_column_name === 'status' && header.isDownload) {
                          return (
                            <TableCell key={rowId + '_' + db_column_name}>
                              {(row[db_column_name] === header.downloadValue &&
                                header.isDownload === true) ||
                              header.isDownload === 'true' ? (
                                <S3DownloadLink url={row[header.downloadKey]} />
                              ) : (
                                renderStatus(
                                  row[db_column_name],
                                  row[db_column_name]?.toLowerCase()
                                )
                              )}
                            </TableCell>
                          );
                        } else if (header.isDownload) {
                          return (
                            <TableCell key={rowId + '_' + db_column_name}>
                              {row[header.db_column_name] !== null &&
                              row[header.db_column_name] !== '' ? (
                                <S3DownloadLink url={row[header.db_column_name]} />
                              ) : (
                                row[header.db_column_name]
                              )}
                            </TableCell>
                          );
                        } else if (header.formattedCurrency) {
                          return (
                            <TableCell key={rowId + '_' + db_column_name}>
                              {toFormattedCurrency(
                                row[header.db_column_name],
                                header.formatCurrencyOptions || {}
                              )}
                            </TableCell>
                          );
                        } else if (
                          header.isCustom &&
                          header.customMethod &&
                          (db_column_name === 'totalPrice' || db_column_name === 'subCategory')
                        ) {
                          return (
                            <TableCell key={rowId + '_' + header_name}>
                              {header.customMethod(row)}
                            </TableCell>
                          );
                        } else if (header.isCustom && header.customMethod) {
                          const status = header.customMethod(row);
                          if (typeof status === 'string') {
                            return (
                              <TableCell key={rowId + '_' + header_name}>
                                {renderStatus(
                                  header.customMethod(row),
                                  header.customMethod(row)?.toLowerCase()
                                )}
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell key={rowId + '_' + header_name}>
                                {header.customMethod(row)}
                              </TableCell>
                            );
                          }
                        } else if (
                          db_column_name === 'state' ||
                          db_column_name === 'status' ||
                          db_column_name.includes('Status')
                        ) {
                          let status = row[db_column_name];
                          if (db_column_name === 'bappebtiKycStatus')
                            status =
                              row.bappebtiKycStatus === null ? 'Pending' : row.bappebtiKycStatus;
                          return (
                            <TableCell key={rowId + '_' + db_column_name}>
                              {renderStatus(status, status?.toLowerCase())}
                            </TableCell>
                          );
                        } else if (db_column_name === 'sendMail' && header.isSend) {
                          return (
                            <TableCell key={rowId + '_' + db_column_name}>
                              {header &&
                              header.emailId &&
                              row[header.emailId] &&
                              row[header.html] ? (
                                <Button
                                  sx={{ mr: 2 }}
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    sendMail(row[header.emailId], row[header.html], row[header.id])
                                  }>
                                  Send Mail
                                </Button>
                              ) : (
                                ''
                              )}
                            </TableCell>
                          );
                        } else if (
                          dateColumnNames.includes(db_column_name) ||
                          header.formatAsDate
                        ) {
                          return (
                            <TableCell key={rowId + '_' + db_column_name}>
                              {dateFormatter(row[db_column_name])}
                            </TableCell>
                          );
                        } else if (
                          db_column_name === 'moneyFee' ||
                          db_column_name === 'moneyFine' ||
                          db_column_name === 'rewardAmount'
                        ) {
                          return (
                            <TableCell key={rowId + '_' + db_column_name}>
                              {row[db_column_name] || 0}
                            </TableCell>
                          );
                        } else if (db_column_name === 'paymentChannel') {
                          return (
                            <TableCell key={rowId + '_' + db_column_name}>
                              {row[db_column_name] || 'pending payment'}
                            </TableCell>
                          );
                        } else if (db_column_name === 'transactionType' && formatAsStatus) {
                          return (
                            <TableCell key={rowId + '_' + db_column_name}>
                              {row[db_column_name] === 'buy' ||
                              row[db_column_name] === 'credit' ||
                              row[db_column_name] === 'switch_in' ? (
                                <StatusChip color="success" label={row[db_column_name]} />
                              ) : (
                                <StatusChip color="error" label={row[db_column_name]} />
                              )}
                            </TableCell>
                          );
                        } else if (header.formatAsStatus === 'true') {
                          return (
                            <TableCell key={rowId + '_' + db_column_name}>
                              {renderStatus(
                                row[db_column_name],
                                row[db_column_name]?.toLowerCase()
                              )}
                            </TableCell>
                          );
                        } else if (db_column_name === 'clientId') {
                          return (
                            <TableCell key={rowId + '_' + db_column_name}>
                              {PlatformMap[row[db_column_name]]
                                ? PlatformMap[row[db_column_name]]
                                : 'Pluang'}
                            </TableCell>
                          );
                        } else if (db_column_name === 'action') {
                          return (
                            <TableCell key={rowId + '_edit'}>
                              {header.isView &&
                                header.isCashoutTopup &&
                                row.table === Config.CASHOUT_TABLE && (
                                  <Link component={RouterLink} to={`${pathname}/view/${rowId}`}>
                                    <IconButton title="View" color="primary">
                                      <Visibility />
                                    </IconButton>
                                  </Link>
                                )}
                              {header.isView && !header.isCashoutTopup && !header.isUserId && (
                                <Link
                                  component={RouterLink}
                                  to={`${pathname}/view/${
                                    header['id_mapping'] ? row[header['id_mapping']] : rowId
                                  }`}>
                                  <IconButton title="View" color="primary">
                                    <Visibility />
                                  </IconButton>
                                </Link>
                              )}
                              {header.isView &&
                                !header.isCashoutTopup &&
                                header.isUserId &&
                                !header.isTravelRule && (
                                  <Link
                                    component={RouterLink}
                                    to={`${pathname}/view/${row.userId}`}>
                                    <IconButton title="View" color="primary">
                                      <Visibility />
                                    </IconButton>
                                  </Link>
                                )}
                              {header.isView && header.isTravelRule && (
                                <Link
                                  component={RouterLink}
                                  state={{ cryptoTransferData: row }}
                                  to={`${pathname}/view/${rowId}`}>
                                  <IconButton title="View" color="primary">
                                    <Visibility />
                                  </IconButton>
                                </Link>
                              )}
                              {header.isEdit && (
                                <Link component={RouterLink} to={`${pathname}/edit/${rowId}`}>
                                  <IconButton title="Edit" color="warning">
                                    <Edit />
                                  </IconButton>
                                </Link>
                              )}
                              {header.isAdd && (
                                <Link component={RouterLink} to={`${pathname}/add/${rowId}`}>
                                  <IconButton title="Add" color="primary">
                                    <Add />
                                  </IconButton>
                                </Link>
                              )}
                            </TableCell>
                          );
                        } else if (row[db_column_name] === 'roles') {
                          return (
                            <TableCell key={rowId + '_' + db_column_name}>
                              {row[db_column_name].toString()}
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell key={rowId + '_' + db_column_name}>
                            {row[db_column_name]}
                          </TableCell>
                        );
                      })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {!totalElements && <Box sx={{ color: 'red', textAlign: 'center', py: 2 }}>No Data Found</Box>}
      <Container sx={{ alignItems: 'center', justifyContent: 'space-between', mt: 2 }}>
        <Item>
          <Typography>Total Elements: {totalElements || 0}</Typography>
        </Item>
        <Item>
          <Pagination
            color="primary"
            page={activePage}
            shape="rounded"
            showFirstButton
            showLastButton
            siblingCount={1}
            count={totalPages}
            onChange={(e, page) => handlePageChange(page)}
          />
        </Item>
      </Container>
    </Box>
  );
};

PluangTable.propTypes = {
  data: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
  totalPages: PropTypes.number,
  totalElements: PropTypes.number,
  pageSize: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func,
  activePage: PropTypes.number,
  roles: PropTypes.array
};

export default PluangTable;
