import { Add } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Divider, Link, Typography } from '@mui/material';
import FilterList from 'components/molecules/FilterList';
import PluangTable from 'components/molecules/PluangTable';
import UploadFile from 'components/molecules/UploadFile';
import { REMOTE } from 'constants/serverRoutes';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { makeRequest } from 'services';
import { useNotification } from 'services/hooks';
import { useStateValue } from 'utils/redux';

const PluangListPage = (props) => {
  const {
    deactivateLocationSearch,
    exportFixedQuery,
    filters,
    filterDateRange,
    headerChild,
    headers,
    isAddNew,
    pageSize,
    resource,
    title,
    fixedQuery,
    isAddNewForm,
    isShowApprovalList,
    approvalListSlug,
    approvalListName,
    newFormName,
    newFormSlug,
    isUploadFile,
    uploadTemplate,
    uploadButtonText,
    borderColor,
    buttonColor,
    uploadTemplateText,
    uploadFixedQuery
  } = props;
  const isInitialMount = useRef(true);
  const [isMounted, setIsMounted] = useState(true);
  const PAGE_SIZE = pageSize || 20;
  const [activePage, setActivePage] = useState(1);
  const [data, setData] = useState([]);
  const [lastTimeFetchData, setLastTimeFetchData] = useState();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [query, setQuery] = useState({});
  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, settotalPages] = useState(0);
  const [appState] = useStateValue();
  const { showNotification } = useNotification();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;

  useEffect(() => {
    setIsMounted(true);
    if (isInitialMount.current) {
      if (!filters || filters.length === 0) getTableData();
      isInitialMount.current = false;
    } else {
      if (isMounted) {
        setLocationSearch({ ...query, activePage });
        getTableData();
      }
    }
    return () => {
      setIsMounted(false);
    };
  }, [activePage, query]);

  const setLocationSearch = (query) => {
    if (!deactivateLocationSearch) {
      const search = Object.keys(query)
        .map(
          (k) =>
            k + '=' + (query[k] === null || query[k] === '' || query[k] === 'null' ? '' : query[k])
        )
        .join('&');
      navigate(pathname + '?' + search);
    }
  };

  const handlePageChange = (pageNumber) => setActivePage(pageNumber);

  const submitQuery = (q) => {
    if (!isButtonDisabled) {
      const page = q?.activePage || 1;
      setIsButtonDisabled(true);
      q && setActivePage(page);
      q && setQuery((prevState) => ({ ...prevState, ...q }));
    }
  };

  const getTableData = () => {
    makeRequest({
      url: `${REMOTE[resource]}/filter`,
      params: {
        page: activePage,
        limit: PAGE_SIZE,
        ...query,
        ...fixedQuery
      }
    })
      .then((result) => {
        if (result.data?.data) {
          result.data = result.data.data.data ? result.data.data : result.data;
          setData(result.data.data);
          settotalPages(result.data.pages.totalPages);
          setTotalElements(result.data.pages.totalElements);
          setLastTimeFetchData(result.data.lastTimeFetchData);
        }
      })
      .catch((err) => {
        if (err?.status === 422) {
          let message = err.message;
          if (err?.errors) {
            message = `${Object.keys(err?.errors).join(',')} ${message.toLowerCase()}`;
          }
          showNotification('error', message);
        } else {
          showNotification('error', err?.error_message);
        }
      })
      .finally(() => setIsButtonDisabled(false));
  };

  const exportTableData = (query) => {
    setIsButtonDisabled(true);

    makeRequest({
      url: `${REMOTE[resource]}/export`,
      params: { ...query, ...exportFixedQuery },
      responseType: 'blob'
    })
      .then((result) => {
        if (result.data && result.data.success) {
          showNotification('success', 'Success', result.data.message);
        }
      })
      .finally(() => setIsButtonDisabled(false));
  };

  const onRegisterToKseiData = (query) => {
    setIsButtonDisabled(true);
    makeRequest({
      url: `${REMOTE.USER_BANK}/download`,
      params: { ...query, ...exportFixedQuery }
    })
      .then((result) => {
        if (result && result.data && result.data.success) {
          showNotification('success', 'Success', result.data.message);
        }
      })
      .finally(() => setIsButtonDisabled(false));
  };

  const uploadFile = (event) => {
    if (!event || !event.target || !event.target.files || event.target.files.length === 0) {
      return;
    }

    const url = `${REMOTE[resource]}/upload`;
    let data;
    data = new FormData();
    data.append('file', event.target.files[0]);
    // Clear the file
    event.target.value = '';
    setIsButtonDisabled(true);
    makeRequest({
      url,
      params: { ...uploadFixedQuery },
      data,
      method: 'POST'
    })
      .then((result) => {
        showNotification('success', 'Success');
        if (result && result.data && result.data.success) {
          if (result.data.type.toUpperCase() === 'INSURANCE') {
            // List the total success count and failure count
            showNotification('success', `Success Count ${result.data.successCount}`);
            showNotification('error', `Failure Count ${result.data.failureCount}`);
          }
        }
      })
      .finally(() => setIsButtonDisabled(false));
  };

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h5">{title}</Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
            {headerChild && headerChild()}
            {isAddNewForm && (
              <Box sx={{ display: 'flex', gap: 1 }}>
                {isShowApprovalList && (
                  <Link component={RouterLink} to={`${pathname}/${approvalListSlug}`}>
                    <Button size="small" variant="contained">
                      {approvalListName}
                    </Button>
                  </Link>
                )}
                <Link
                  component={RouterLink}
                  to={`${pathname}/${newFormSlug ? newFormSlug : 'addForm'}/`}>
                  <Button size="small" variant="contained" startIcon={<Add />}>
                    {newFormName || ''}
                  </Button>
                </Link>
              </Box>
            )}
            {isAddNew && (
              <Box>
                <Link component={RouterLink} to={`${pathname}/edit/`}>
                  <Button size="small" variant="contained" startIcon={<Add />}>
                    Add New
                  </Button>
                </Link>
              </Box>
            )}
            {isUploadFile && (
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <Box component="a" href={uploadTemplate} style={{ fontSize: '13px' }}>
                  {uploadTemplateText || 'Template'}
                </Box>
                <UploadFile
                  buttonText={uploadButtonText}
                  disabled={isButtonDisabled}
                  buttonColor={buttonColor}
                  borderColor={borderColor}
                  onChange={uploadFile}
                />
              </Box>
            )}
          </Box>
        </Box>
        {lastTimeFetchData && (
          <Box sx={{ display: 'flex' }}>Last Statement Fetch Time : {lastTimeFetchData}</Box>
        )}
        {filters?.length > 0 && (
          <>
            <Divider sx={{ my: 2 }} />
            <FilterList
              onSubmit={submitQuery}
              onExport={exportTableData}
              onRegisterToKsei={onRegisterToKseiData}
              filters={filters}
              filterDateRange={filterDateRange}
              isButtonDisabled={isButtonDisabled}
              resource={resource}
            />
          </>
        )}
        <Divider sx={{ mt: 2 }} />
        <PluangTable
          headers={headers}
          roles={appState.user?.roles}
          data={data}
          totalElements={totalElements}
          totalPages={totalPages}
          pageSize={PAGE_SIZE}
          activePage={activePage}
          handlePageChange={handlePageChange}
        />
      </CardContent>
    </Card>
  );
};

PluangListPage.propTypes = {
  deactivateLocationSearch: PropTypes.bool,
  exportFixedQuery: PropTypes.object,
  filters: PropTypes.array,
  filterDateRange: PropTypes.number,
  headerChild: PropTypes.func,
  headers: PropTypes.array.isRequired,
  isAddNew: PropTypes.bool,
  pageSize: PropTypes.number,
  resource: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  fixedQuery: PropTypes.object,
  isShowApprovalList: PropTypes.bool,
  isAddNewForm: PropTypes.bool,
  approvalListSlug: PropTypes.string,
  approvalListName: PropTypes.string,
  newFormName: PropTypes.string,
  newFormSlug: PropTypes.string,
  isUploadFile: PropTypes.bool,
  uploadTemplate: PropTypes.string,
  uploadButtonText: PropTypes.string,
  borderColor: PropTypes.string,
  buttonColor: PropTypes.string,
  uploadTemplateText: PropTypes.string,
  uploadFixedQuery: PropTypes.object
};

export default PluangListPage;
