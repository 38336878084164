import { Box, Button, Typography } from '@mui/material';
import AlertDialog from 'components/molecules/AlertDialog';
import BasicTable from 'components/molecules/BasicTable';
import EditDialog from 'components/molecules/EditDialog';
import KycTask from 'components/molecules/KycTask';
import KtpOcrForm from 'components/organisms/OcrDetails/KtpOcrForm';
import { REMOTE } from 'constants/serverRoutes';
import ktpOCRValidation from 'containers/ops/kyc/ktpOcrValidation';
import { Country } from 'country-state-city';
import { dateFormatter } from 'helpers/dateChanger';
import { formatDateTime } from 'helpers/formatDate';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { makeRequest } from 'services';
import { useKyc, useLoader, useNotification } from 'services/hooks';
import { useStateValue } from 'utils/redux';
import { OPEN_MODAL } from 'utils/redux/actions';

const COUNTRIES = Country.getAllCountries();

const OcrDetails = ({
  startTask,
  startInterval,
  endTask,
  escalateTask,
  ktpOcrData: ktpOcr,
  escalate,
  kyc1Data,
  basicKtpDetails,
  provinceCollection: provinceCollectionProps,
  setKtpOcrData,
  setEscalate,
  isAutoAssign = true
}) => {
  const [, setLoader] = useLoader();
  const [, dispatch] = useStateValue();
  const [showDetail, setShowDetail] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { showNotification } = useNotification();
  const { getCitiesBasedOnProvince, getDistrictsBasedOnCity, getSubDistrictsBasedOnDistrict } =
    useKyc();

  const [ocrState, setOcrState] = useState({
    idCardPicture: '',
    nameOnIdentityCard: '',
    dateOfBirth: '',
    ktpOcrDateOfBirth: '',
    identityNumber: '',
    citizenship: '',
    countryOfBirth: null,
    placeOfBirth: '',
    address: '',
    rtRw: '',
    subDistrict: '',
    subDistrictId: '',
    district: '',
    city: '',
    cityCode: '',
    province: '',
    provinceCode: '',
    ktpOcrMetaData: '',
    ktpOcrId: '',
    ktpOcrData: {},
    npwpPicture: '',
    bahasaOptions: [],
    englishOptions: [],
    showModal: false,
    viewImage: '',
    deg: 0,
    scale: 1,
    flip: 1,
    occupation: '',
    religion: '',
    gender: '',
    bloodType: '',
    maritalStatus: '',
    expirationDate: '',
    issueDate: '',
    provinceCollection: [],
    cityCollection: [],
    districtCollection: [],
    subDistrictCollection: [],
    occupationCollection: [],
    religionCollection: [],
    bloodCollection: [],
    genderCollection: [],
    maritalStatusCollection: [],
    nationality: 'Indonesia',
    dateOfSubmission: ''
  });

  useEffect(() => {
    getKtpOcrData(ktpOcr);
  }, [ktpOcr]);

  useEffect(() => {
    if (ocrState.province && ocrState.province.provinceCode) {
      getCitiesBasedOnProvince(ocrState, setOcrState);
    }
  }, [ocrState.province.provinceCode]);

  useEffect(() => {
    if (ocrState.city && ocrState.city.cityCode) {
      getDistrictsBasedOnCity(ocrState, setOcrState);
    }
  }, [ocrState.city.cityCode]);

  useEffect(() => {
    if (ocrState.district && ocrState.district.id) {
      getSubDistrictsBasedOnDistrict(ocrState, setOcrState);
    }
  }, [ocrState.district.id]);

  const handleDropdown = (action) => {
    setShowDetail(!action);
  };

  const getKtpOcrData = (ktpOcrData) => {
    if (Object.keys(ktpOcrData).length > 1) {
      let countryOfBirth = COUNTRIES.map((x) => x.name).includes(ktpOcrData?.countryOfBirth)
        ? ktpOcrData.countryOfBirth
        : null;
      let province = (ktpOcrData.address && ktpOcrData.address['province']) || '';
      if (province !== '' && provinceCollectionProps.length > 0) {
        let provinceFromCollection = provinceCollectionProps.find((provinceObj) => {
          return provinceObj.provinceName === province;
        });
        if (provinceFromCollection) {
          province = provinceFromCollection;
        }
      }
      setOcrState((prevState) => ({
        ...prevState,
        idCardPicture: kyc1Data.idCardPicture,
        ktpOcrData: ktpOcrData,
        provinceCollection: provinceCollectionProps,
        occupationCollection: basicKtpDetails.occupationCollection,
        religionCollection: basicKtpDetails.religionCollection,
        bloodCollection: basicKtpDetails.bloodCollection,
        genderCollection: basicKtpDetails.genderCollection,
        maritalStatusCollection: basicKtpDetails.maritalStatusCollection,
        ktpOcrId: ktpOcrData.id,
        ktpOcrMetaData: ktpOcrData.meta,
        nameOnIdentityCard: ktpOcrData.nameOnIdentityCard,
        ktpOcrDateOfBirth: ktpOcrData.dateOfBirth,
        identityNumber: ktpOcrData.identityNumber,
        citizenship: ktpOcrData.citizenship || '',
        countryOfBirth,
        placeOfBirth: ktpOcrData.placeOfBirth || '',
        cityCode: ktpOcrData.cityCode,
        address: (ktpOcrData.address && ktpOcrData.address['address']) || '',
        rtRw: (ktpOcrData.address && ktpOcrData.address['rtRw']) || '',
        subDistrict: (ktpOcrData.address && ktpOcrData.address['subDistrict']) || '',
        subDistrictId: (ktpOcrData.address && ktpOcrData.address['subDistrictId']) || '',
        district: (ktpOcrData.address && ktpOcrData.address['district']) || '',
        city: (ktpOcrData.address && ktpOcrData.address['city']) || '',
        province: province,
        provinceCode: (ktpOcrData.address && ktpOcrData.address['provinceCode']) || '',
        occupation: ktpOcrData.occupation || ktpOcrData.meta.occupation,
        religion: ktpOcrData.religion || ktpOcrData.meta.religion || '',
        gender: ktpOcrData.gender || ktpOcrData.meta.gender || '',
        maritalStatus: ktpOcrData.maritalStatus || ktpOcrData.meta.maritalStatus || '',
        bloodType: ktpOcrData.bloodType || ktpOcrData.meta.bloodType || '',
        expirationDate: ktpOcrData.expirationDate || ktpOcrData.meta.expirationDate || new Date(),
        issueDate: ktpOcrData.issueDate || ktpOcrData.meta.issueDate || null
      }));
    } else {
      setOcrState((prevState) => ({
        ...prevState,
        ktpOcrData
      }));
    }
  };

  // Used for updating ktpOcr Data
  const handleSubmit = () => {
    const {
      ktpOcrDateOfBirth,
      nameOnIdentityCard,
      identityNumber,
      citizenship,
      countryOfBirth,
      placeOfBirth,
      address,
      rtRw,
      subDistrict,
      district,
      city,
      province,
      provinceCode,
      subDistrictId,
      cityCode,
      occupation,
      religion,
      gender,
      bloodType,
      maritalStatus,
      expirationDate,
      issueDate,
      ktpOcrData,
      nationality
    } = ocrState;
    let data = {
      userId: ocrState.ktpOcrData.userId,
      id: ocrState.ktpOcrId,
      meta: ocrState.ktpOcrMetaData,
      dateOfBirth: ktpOcrDateOfBirth ? formatDateTime(ktpOcrDateOfBirth) : ktpOcrDateOfBirth,
      nameOnIdentityCard,
      identityNumber,
      citizenship,
      countryOfBirth,
      placeOfBirth,
      occupation,
      religion,
      gender,
      bloodType,
      maritalStatus,
      expirationDate: expirationDate ? formatDateTime(expirationDate) : null,
      issueDate: issueDate ? formatDateTime(issueDate) : null,
      addressJson: {
        address: address.trim(),
        rtRw,
        subDistrict: subDistrict.subDistrictName || subDistrict,
        subDistrictId: subDistrict.id || subDistrictId,
        district: district.districtName || district,
        city: city.cityName || city,
        province: province.provinceName || province,
        provinceCode: province.provinceCode || provinceCode
      },
      cityCode,
      nationality,
      basicDetailVerifiedById: ktpOcrData.basicDetailVerifiedById,
      verifiedById: ktpOcrData.verifiedById
    };
    if (isAutoAssign) {
      data['isKycAutoAssignPage'] = true;
    }
    const errors = ktpOCRValidation(data);
    if (errors.length > 0) {
      errors.forEach((errorText) => {
        showNotification('error', errorText.body, errorText.title);
      });
      return;
    }
    setLoader(true);
    makeRequest({
      method: 'PUT',
      url: `${REMOTE.KYC1}/ktpOcr`,
      data
    })
      .then((result) => {
        if (result.data && result.data.success) {
          let ktpOcrData = result.data;
          showNotification('success', 'Ktp Ocr Data updated');
          setKtpOcrData(ktpOcrData);
          endTask();
          startInterval();
          handleDropdown(false);
          setShowModal(false);
        }
      })
      .catch((err) => {
        if (err.status === 422) {
          Object.values(err.errors).forEach((error) => {
            showNotification('error', error.msg);
          });
        }
      })
      .finally(() => {
        setOcrState((prevState) => ({
          ...prevState,
          isButtonDisabled: false
        }));
        setLoader(false);
      });
  };

  const escalateOCRTask = () => {
    escalateTask('OCR_VERIFICATION', () => {
      setEscalate((prevState) => ({
        ...prevState,
        isKtpOcrEscalated: true
      }));
    });
  };

  const { ktpOcrData } = ocrState;

  const ktpOcrTableData = [
    { label: 'Citizenship', value: ktpOcr.citizenship },
    { label: 'Country Of Birth', value: ktpOcr.countryOfBirth },
    { label: 'Place Of Birth', value: ktpOcr.placeOfBirth },
    { label: 'Address', value: (ktpOcr.address && ktpOcr.address['address']) || '' },
    { label: 'RT/RW', value: (ktpOcr.address && ktpOcr.address['rtRw']) || '' },
    { label: 'Province', value: (ktpOcr.address && ktpOcr.address['province']) || '' },
    { label: 'City', value: (ktpOcr.address && ktpOcr.address['city']) || '' },
    { label: 'District', value: (ktpOcr.address && ktpOcr.address['district']) || '' },
    { label: 'Sub-District', value: (ktpOcr.address && ktpOcr.address['subDistrict']) || '' },
    { label: 'Occupation', value: ktpOcr.occupation || ktpOcr.meta?.occupation },
    { label: 'Religion', value: ktpOcr.religion || ktpOcr.meta?.religion },
    { label: 'Blood Type', value: ktpOcr.bloodType || ktpOcr.meta?.bloodType },
    { label: 'Gender', value: ktpOcr.gender || ktpOcr.meta?.gender },
    { label: 'Marital Status', value: ktpOcr.maritalStatus || ktpOcr.meta?.maritalStatus },
    {
      label: 'Expiration Date',
      value: ktpOcr.expirationDate ? dateFormatter(ktpOcr.expirationDate) : ''
    },
    {
      label: 'Issue Date',
      value:
        ktpOcr?.issueDate || ktpOcr?.meta?.issueDate
          ? dateFormatter(ktpOcr?.issueDate || ktpOcr?.meta?.issueDate)
          : ''
    },
    { label: 'Last Update time', value: ktpOcr.updatedAt ? dateFormatter(ktpOcr.updatedAt) : '' },
    { label: 'Last Upload time', value: ktpOcr.createdAt ? dateFormatter(ktpOcr.createdAt) : '' }
  ];

  const kycTaskStatus = () => (
    <>
      {escalate && escalate.isKtpOcrEscalated && (
        <Typography sx={{ border: 1, color: 'red', mr: 2, p: '2px' }}>ESCALATED</Typography>
      )}
      {ktpOcr && ktpOcr.verifiedById ? (
        <Typography sx={{ color: 'green' }}>VERIFIED</Typography>
      ) : ktpOcr.status === 'NOT UPLOADED' ? (
        ''
      ) : (
        <Typography>UPLOADED</Typography>
      )}
    </>
  );

  const handleKycTaskClick = () => {
    if (!ktpOcr.verifiedById && !escalate.isKtpOcrEscalated && isAutoAssign) {
      startTask('OCR_VERIFICATION');
    }
    handleDropdown(showDetail);
  };

  const isTaskDisabled = ktpOcr.status === 'NOT UPLOADED';

  return (
    <KycTask
      title="OCR Data"
      status={kycTaskStatus()}
      onClick={handleKycTaskClick}
      disabled={isTaskDisabled}
      open={showDetail}>
      <BasicTable tableData={ktpOcrTableData} />
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, my: 2 }}>
        <Button
          color="primary"
          size="small"
          variant="contained"
          onClick={() => setShowModal(true)}
          disabled={escalate.isKtpOcrEscalated && isAutoAssign}>
          Edit Data
        </Button>
        {ktpOcr && !ktpOcr.verifiedById && isAutoAssign && (
          <Button
            color="primary"
            size="small"
            variant="outlined"
            onClick={() =>
              dispatch({
                type: OPEN_MODAL,
                modalContent: (
                  <AlertDialog
                    title="Escalate OCR Data"
                    alertMessage="Are you sure want to escalate?"
                    onConfirm={escalateOCRTask}
                  />
                )
              })
            }
            disabled={escalate.isKtpOcrEscalated}>
            {escalate.isKtpOcrEscalated ? 'Escalated' : 'Escalate'}
          </Button>
        )}
      </Box>
      <EditDialog
        showModal={showModal}
        setShowModal={setShowModal}
        title="KTP OCR DATA"
        ktpOcrData={ktpOcrData}
        idCardPicture={ocrState.idCardPicture}
        handleSubmit={handleSubmit}>
        <KtpOcrForm ocrState={ocrState} setOcrState={setOcrState} />
      </EditDialog>
    </KycTask>
  );
};

OcrDetails.propTypes = {
  startTask: PropTypes.func,
  startInterval: PropTypes.func,
  endTask: PropTypes.func,
  escalateTask: PropTypes.func,
  ktpOcrData: PropTypes.object,
  escalate: PropTypes.object,
  setEscalate: PropTypes.func,
  kyc1Data: PropTypes.object,
  basicKtpDetails: PropTypes.object,
  provinceCollection: PropTypes.array,
  setKtpOcrData: PropTypes.func,
  isAutoAssign: PropTypes.bool
};

export default OcrDetails;
