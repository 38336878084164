import { BUTTON_ACTION, COINLABELS } from 'containers/fi/cryptoCurrency/coinStates/constants';
import Joi from 'joi';

const inputValidation = (value, helpers, inputFieldEn, inputFieldId) => {
  const {
    state: { ancestors, path }
  } = helpers;
  if (
    (ancestors[0][inputFieldEn] === '' && ancestors[0][inputFieldId] === '') ||
    (ancestors[0][inputFieldEn] !== '' && ancestors[0][inputFieldId] !== '')
  ) {
    return value;
  }
  return helpers.error('custom.invalid');
};

export const cryptoCurrencySafetyLabelSchema = Joi.object({
  label: Joi.string()
    .valid(...Object.values(COINLABELS))
    .required(),
  active: Joi.boolean().required(),
  endDate: Joi.date().required(),
  assetPageAnnouncement: Joi.object({
    active: Joi.boolean(),
    titleEn: Joi.when('active', {
      is: true,
      then: Joi.when(Joi.ref('/label'), {
        is: Joi.valid(COINLABELS.DELISTING_SOON, COINLABELS.DELISTED),
        then: Joi.string().required().messages({
          'string.empty': 'Custom text is required when label is Delisting Soon or Delisted'
        }),
        otherwise: Joi.string()
          .allow('')
          .custom((value, helpers) => inputValidation(value, helpers, 'titleEn', 'titleId'))
          .messages({
            'custom.invalid': 'Both the title, En and Id fields should be either empty or filled'
          })
      }),
      otherwise: Joi.string().allow('')
    }),
    titleId: Joi.when('active', {
      is: true,
      then: Joi.when(Joi.ref('/label'), {
        is: Joi.valid(COINLABELS.DELISTING_SOON, COINLABELS.DELISTED),
        then: Joi.string().required().messages({
          'string.empty': 'Custom text is required when label is Delisting Soon or Delisted'
        }),
        otherwise: Joi.string()
          .allow('')
          .custom((value, helpers) => inputValidation(value, helpers, 'titleEn', 'titleId'))
          .messages({
            'custom.invalid': 'Both the title, En and Id fields should be either empty or filled'
          })
      }),
      otherwise: Joi.string().allow('')
    }),
    endDate: Joi.date().required()
  })
    .allow(null)
    .required(),
  tradingButtonsAnnouncement: Joi.object({
    active: Joi.boolean().required(),
    titleEn: Joi.when('active', {
      is: true,
      then: Joi.when(Joi.ref('/label'), {
        is: Joi.valid(COINLABELS.DELISTING_SOON, COINLABELS.DELISTED),
        then: Joi.string().required().messages({
          'string.empty': 'Custom text is required when label is Delisting Soon or Delisted'
        }),
        otherwise: Joi.string()
          .allow('')
          .custom((value, helpers) => inputValidation(value, helpers, 'titleEn', 'titleId'))
          .messages({
            'custom.invalid': 'Both the title, En and Id fields should be either empty or filled'
          })
      }),
      otherwise: Joi.string().allow('')
    }),
    titleId: Joi.when('active', {
      is: true,
      then: Joi.when(Joi.ref('/label'), {
        is: Joi.valid(COINLABELS.DELISTING_SOON, COINLABELS.DELISTED),
        then: Joi.string().required().messages({
          'string.empty': 'Custom text is required when label is Delisting Soon or Delisted'
        }),
        otherwise: Joi.string()
          .allow('')
          .custom((value, helpers) => inputValidation(value, helpers, 'titleEn', 'titleId'))
          .messages({
            'custom.invalid': 'Both the title, En and Id fields should be either empty or filled'
          })
      }),
      otherwise: Joi.string().allow('')
    }),
    url: Joi.string().uri().allow(null, '').label('Url').required(),
    endDate: Joi.date().required()
  })
    .allow(null)
    .required(),
  fullPageAnnouncement: Joi.object({
    active: Joi.boolean().required(),
    endDate: Joi.date().required(),
    url: Joi.string().uri().allow(null, '').label('Url').required(),
    titleEn: Joi.when('active', {
      is: true,
      then: Joi.when(Joi.ref('/label'), {
        is: Joi.valid(COINLABELS.DELISTING_SOON, COINLABELS.DELISTED),
        then: Joi.string().required().messages({
          'string.empty': 'Title is required when label is Delisting Soon or Delisted'
        }),
        otherwise: Joi.string()
          .allow('')
          .custom((value, helpers) => inputValidation(value, helpers, 'titleEn', 'titleId'))
          .messages({
            'custom.invalid': 'Both the title, En and Id fields should be either empty or filled'
          })
      }),
      otherwise: Joi.string().allow('')
    }),
    titleId: Joi.when('active', {
      is: true,
      then: Joi.when(Joi.ref('/label'), {
        is: Joi.valid(COINLABELS.DELISTING_SOON, COINLABELS.DELISTED),
        then: Joi.string().required().messages({
          'string.empty': 'Title is required when label is Delisting Soon or Delisted'
        }),
        otherwise: Joi.string()
          .allow('')
          .custom((value, helpers) => inputValidation(value, helpers, 'titleEn', 'titleId'))
          .messages({
            'custom.invalid': 'Both the title, En and Id fields should be either empty or filled'
          })
      }),
      otherwise: Joi.string().allow('')
    }),
    descriptionEn: Joi.when('active', {
      is: true,
      then: Joi.when(Joi.ref('/label'), {
        is: Joi.valid(COINLABELS.DELISTING_SOON, COINLABELS.DELISTED),
        then: Joi.string().required().messages({
          'string.empty': 'Description is required when label is Delisting Soon or Delisted'
        }),
        otherwise: Joi.string()
          .allow('')
          .custom((value, helpers) =>
            inputValidation(value, helpers, 'descriptionEn', 'descriptionId')
          )
          .messages({
            'custom.invalid':
              'Both the description, En and Id fields should be either empty or filled'
          })
      }),
      otherwise: Joi.string().allow('')
    }),
    descriptionId: Joi.when('active', {
      is: true,
      then: Joi.when(Joi.ref('/label'), {
        is: Joi.valid(COINLABELS.DELISTING_SOON, COINLABELS.DELISTED),
        then: Joi.string().required().messages({
          'string.empty': 'Description is required when label is Delisting Soon or Delisted'
        }),
        otherwise: Joi.string()
          .allow('')
          .custom((value, helpers) =>
            inputValidation(value, helpers, 'descriptionEn', 'descriptionId')
          )
          .messages({
            'custom.invalid':
              'Both the description, En and Id fields should be either empty or filled'
          })
      }),
      otherwise: Joi.string().allow('')
    }),
    actionStepsDescId: Joi.when('active', {
      is: true,
      then: Joi.when(Joi.ref('/label'), {
        is: Joi.valid(COINLABELS.DELISTING_SOON, COINLABELS.DELISTED),
        then: Joi.string().required().messages({
          'string.empty': 'Body is required when label is Delisting Soon or Delisted'
        }),
        otherwise: Joi.string()
          .allow('')
          .custom((value, helpers) =>
            inputValidation(value, helpers, 'actionStepsDescEn', 'actionStepsDescId')
          )
          .messages({
            'custom.invalid': 'Both the body, En and Id fields should be either empty or filled'
          })
      }),
      otherwise: Joi.string().allow('')
    }),
    actionStepsDescEn: Joi.when('active', {
      is: true,
      then: Joi.when(Joi.ref('/label'), {
        is: Joi.valid(COINLABELS.DELISTING_SOON, COINLABELS.DELISTED),
        then: Joi.string().required().messages({
          'string.empty': 'Body is required when label is Delisting Soon or Delisted'
        }),
        otherwise: Joi.string()
          .allow('')
          .custom((value, helpers) =>
            inputValidation(value, helpers, 'actionStepsDescEn', 'actionStepsDescId')
          )
          .messages({
            'custom.invalid': 'Both the body, En and Id fields should be either empty or filled'
          })
      }),
      otherwise: Joi.string().allow('')
    }),
    actionStepsTitleEn: Joi.when('active', {
      is: true,
      then: Joi.when(Joi.ref('/label'), {
        is: Joi.valid(COINLABELS.DELISTING_SOON, COINLABELS.DELISTED),
        then: Joi.string().required().messages({
          'string.empty': 'Sub-title is required when label is Delisting Soon or Delisted'
        }),
        otherwise: Joi.string()
          .allow('')
          .custom((value, helpers) =>
            inputValidation(value, helpers, 'actionStepsTitleEn', 'actionStepsTitleId')
          )
          .messages({
            'custom.invalid':
              'Both the sub-title, En and Id fields should be either empty or filled'
          })
      }),
      otherwise: Joi.string().allow('')
    }),
    actionStepsTitleId: Joi.when('active', {
      is: true,
      then: Joi.when(Joi.ref('/label'), {
        is: Joi.valid(COINLABELS.DELISTING_SOON, COINLABELS.DELISTED),
        then: Joi.string().required().messages({
          'string.empty': 'Sub-title is required when label is Delisting Soon or Delisted'
        }),
        otherwise: Joi.string()
          .allow('')
          .custom((value, helpers) =>
            inputValidation(value, helpers, 'actionStepsTitleEn', 'actionStepsTitleId')
          )
          .messages({
            'custom.invalid':
              'Both the sub-title, En and Id fields should be either empty or filled'
          })
      }),
      otherwise: Joi.string().allow('')
    }),
    buttonAction: Joi.string()
      .valid(...Object.values(BUTTON_ACTION))
      .required()
  })
    .allow(null)
    .required(),
  hiddenScreens: Joi.object({
    explore: Joi.boolean().required(),
    all: Joi.boolean().required()
  }).required()
});
