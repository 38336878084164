import {
  AccountBalance,
  AccountBox,
  AccountCircle,
  Add,
  AdminPanelSettings,
  Article,
  AttachMoney,
  BentoRounded,
  Calculate,
  CalendarMonthRounded,
  Campaign,
  CardGiftcard,
  Code,
  ContactMail,
  ContactSupport,
  CurrencyExchange,
  Edit,
  Email,
  EmojiEvents,
  FactCheck,
  FormatListBulleted,
  FormatListNumbered,
  Group,
  GroupAddRounded,
  History,
  HourglassBottom,
  Image,
  InsertPhotoOutlined,
  LocalAtm,
  LocalOffer,
  ManageAccounts,
  Newspaper,
  Percent,
  Person,
  PriceChange,
  PriceCheck,
  Savings,
  SupervisedUserCircle,
  Sync,
  TrackChanges,
  TrendingUp,
  ViewInAr,
  Visibility,
  Wallet
} from '@mui/icons-material';

export const superAdminContent = [
  {
    Icon: AdminPanelSettings,
    name: 'Super Admin',
    items: [
      {
        Icon: AccountCircle,
        name: 'Admin',
        link: '/super/admin'
      },
      {
        Icon: SupervisedUserCircle,
        name: 'Admin Roles',
        link: '/super/adminRoles'
      }
    ]
  }
];

export const sidebarMenuItems = [
  {
    Icon: GroupAddRounded,
    name: 'Account Manager',
    items: [
      {
        Icon: Group,
        name: 'Partner',
        link: '/ac/partner'
      },
      {
        Icon: Group,
        name: 'Merchant',
        link: '/ac/merchant'
      },
      {
        Icon: Group,
        name: 'Influencer',
        link: '/ac/influencer'
      },
      {
        Icon: Group,
        name: 'Fund',
        link: '/ac/fund'
      },
      {
        Icon: Group,
        name: 'Indonesia Stock',
        link: '/ac/indonesiaStocks'
      },
      {
        Icon: Article,
        name: 'Gold Report',
        link: '/ac/goldReport'
      },
      {
        Icon: Group,
        name: 'Competitor',
        link: '/ac/competitor'
      },
      {
        Icon: CalendarMonthRounded,
        name: 'Installment',
        link: '/ac/installment'
      }
    ]
  },
  {
    Icon: ManageAccounts,
    name: 'Operational',
    items: [
      {
        Icon: TrackChanges,
        name: 'Missions',
        items: [
          {
            Icon: InsertPhotoOutlined,
            name: 'Overview',
            link: '/ops/missions/overview',
            permissionKeys: [
              'overviewwithoutapproveanddeletemission',
              'overviewwithapproveanddeletemission',
              'overviewwithpendingapprovalmission'
            ]
          },
          {
            Icon: FormatListNumbered,
            name: 'User Action',
            link: '/ops/missions/userAction'
          },
          {
            Icon: EmojiEvents,
            name: 'Reward Claim',
            link: '/ops/missions/rewardClaim'
          },
          {
            Icon: Group,
            name: 'Mission Partner',
            link: '/ops/missions/partners'
          }
        ]
      },
      {
        Icon: TrackChanges,
        name: 'USD Deposit',
        items: [
          {
            Icon: InsertPhotoOutlined,
            name: 'Forex Topup',
            link: '/ops/usdDeposit/topup'
          },
          {
            Icon: FormatListNumbered,
            name: 'Forex Cashout',
            link: '/ops/usdDeposit/cashout'
          }
        ]
      },
      {
        name: 'Pricing And Fees',
        Icon: ViewInAr,
        items: [
          {
            Icon: PriceChange,
            name: 'Gold Price',
            link: '/ops/price'
          },
          {
            Icon: CurrencyExchange,
            name: 'USD Price',
            link: '/ops/usdPrice'
          },
          {
            Icon: PriceChange,
            name: 'Crypto Fees',
            link: '/ops/cryptoFees'
          },
          {
            Icon: LocalAtm,
            name: 'Fund Price',
            link: '/ops/fundPrice'
          },
          {
            Icon: LocalAtm,
            name: 'Fund AUM',
            link: '/ops/fundAum'
          },
          {
            Icon: LocalAtm,
            name: 'Fund Dividend',
            link: '/ops/fundDividend'
          },
          {
            Icon: PriceChange,
            name: 'Competitor Price',
            link: '/ops/competitorPrice'
          },
          {
            Icon: ViewInAr,
            name: 'Gold Printing Fee',
            link: '/ops/printingFee'
          },
          {
            Icon: PriceChange,
            name: 'Global Single Stock Fees',
            link: '/ops/globalSingleStockFees'
          },
          {
            Icon: PriceChange,
            name: 'GSS Fees - PALN',
            link: '/ops/globalSingleStockFeesPaln'
          }
        ]
      },
      {
        name: 'Users',
        Icon: Person,
        items: [
          {
            Icon: Person,
            name: 'User Info',
            link: '/ops/users'
          },
          {
            Icon: Edit,
            name: 'User Pep Status',
            link: '/ops/pepStatus'
          },
          {
            Icon: ViewInAr,
            name: 'Edd',
            link: '/ops/edd'
          },
          {
            Icon: Person,
            name: 'User Tags',
            link: '/ops/userTags'
          },
          {
            Icon: Person,
            name: 'Bypass Liveliness Check',
            link: '/ops/bypassLivelinessCheck'
          }
        ]
      },
      {
        name: 'Agent Network',
        Icon: Person,
        items: [
          {
            Icon: AccountBox,
            name: 'Add Users',
            link: '/ops/agentNetwork/addUsers'
          },
          {
            Icon: ContactMail,
            name: 'Add KYC2',
            link: '/ops/agentNetwork/kyc2'
          },
          {
            Icon: Edit,
            name: 'Edit User',
            link: '/ops/agentNetwork/edit'
          }
        ]
      },
      {
        Icon: History,
        name: 'Transactions',
        items: [
          {
            Icon: History,
            name: 'Gold Transactions',
            link: '/ops/goldTransactions'
          },
          {
            Icon: History,
            name: 'Gold Send Transactions',
            link: '/ops/goldSendTransactions'
          },
          {
            Icon: History,
            name: 'USD Transactions',
            link: '/ops/USDTransactions'
          },
          {
            Icon: History,
            name: 'Crypto Transactions',
            link: '/ops/cryptoTransactions'
          },
          {
            Icon: History,
            name: 'Crypto Transfers',
            link: '/ops/cryptoTransfers'
          },
          {
            Icon: History,
            name: 'Crypto Future Transactions',
            link: '/ops/cryptoFutureTransactions'
          },
          {
            Icon: History,
            name: 'Crypto Future Funding',
            link: '/ops/cryptoFutureFunding'
          },
          {
            Icon: History,
            name: 'Crypto Future Transfers',
            link: '/ops/cryptoFutureTransfers'
          },
          {
            Icon: History,
            name: 'GSS Transactions CFD',
            link: '/ops/globalSingleStockTransactions'
          },
          {
            Icon: History,
            name: 'GSS Transactions PALN',
            link: '/ops/globalSingleStockTransactionsPaln'
          },
          {
            Icon: History,
            name: 'Pocket Transactions',
            link: '/ops/pocketTransactions'
          },
          {
            Icon: History,
            name: 'ID Stock Transactions',
            link: '/ops/indonesianSingleStockTransactions'
          },
          {
            Icon: History,
            name: 'Options Transactions',
            link: '/ops/optionsTransactions'
          }
        ]
      },
      {
        Icon: TrendingUp,
        name: 'Market Alerts',
        link: '/ops/marketAlerts'
      },
      {
        Icon: AccountBalance,
        name: 'Bank Configuration',
        link: '/ops/bankConfiguration'
      },
      {
        Icon: Edit,
        name: 'Edit Beneficiary Name',
        link: '/ops/editBeneficiaryName'
      },
      {
        Icon: Edit,
        name: 'KYC',
        link: '/ops/kyc/users'
      },
      {
        Icon: Edit,
        name: 'KYC Auto Assign',
        link: '/ops/kyc/agent'
      },
      {
        Icon: Edit,
        name: 'BAG KYC',
        link: '/ops/bagKyc'
      },
      {
        Icon: Edit,
        name: 'Partner Users',
        link: '/ops/partnerUsers'
      },
      {
        Icon: CalendarMonthRounded,
        name: 'Download OA PDF',
        link: '/ops/indoStockUser'
      },
      {
        Icon: CalendarMonthRounded,
        name: 'Installment',
        link: '/ops/installment'
      },
      {
        Icon: CalendarMonthRounded,
        name: 'Dividend',
        link: '/ops/dividend'
      },
      {
        Icon: CalendarMonthRounded,
        name: 'Potential Default Installment',
        link: '/ops/defaulter'
      },
      {
        Icon: CalendarMonthRounded,
        name: 'Auto Debit',
        link: '/ops/autoDebit'
      },
      {
        Icon: ViewInAr,
        name: 'Gold Withdrawal',
        link: '/ops/withdrawal'
      },
      {
        Icon: CardGiftcard,
        name: 'Referral',
        items: [
          {
            Icon: CardGiftcard,
            name: 'User Referral',
            link: '/ops/referral/list'
          },
          {
            Icon: FormatListBulleted,
            name: 'Referral Adjustment',
            link: '/ops/referral/adjustment'
          }
        ]
      },
      {
        Icon: ViewInAr,
        name: 'User Insurance',
        link: '/ops/userInsurance'
      },
      {
        Icon: BentoRounded,
        name: 'District/Sub-district',
        link: '/ops/districtSubDistrict'
      },
      {
        Icon: BentoRounded,
        name: 'GSS Stock Split',
        link: '/ops/gssCorpAction'
      },
      {
        Icon: BentoRounded,
        name: 'GSS Dividends',
        link: '/ops/gssDividends'
      },
      {
        Icon: Edit,
        name: 'IDSS EIPO',
        link: '/ops/eipo'
      }
    ]
  },
  {
    Icon: Group,
    name: 'Customer Service',
    items: [
      {
        Icon: CalendarMonthRounded,
        name: 'Installment',
        link: '/cs/installment'
      },
      {
        Icon: Calculate,
        name: 'Loan Simulation',
        link: '/cs/simulation'
      },
      {
        Icon: ViewInAr,
        name: 'Gold Printing Fee',
        link: '/cs/printingFee'
      },
      {
        Icon: Person,
        name: 'Users',
        link: '/cs/users'
      },
      {
        Icon: Code,
        name: 'Vouchers',
        link: '/cs/vouchers'
      },
      {
        Icon: CardGiftcard,
        name: 'Referral',
        link: '/cs/referral'
      },
      {
        Icon: AccountBalance,
        name: 'User Bank Accounts',
        link: '/cs/userBankAccounts'
      },
      {
        Icon: AccountBalance,
        name: 'User Bank Pending Accounts',
        link: '/cs/userBankPendingAccounts'
      },
      {
        Icon: History,
        name: 'Transactions',
        items: [
          {
            Icon: History,
            name: 'Gold Transactions',
            link: '/cs/goldTransactions'
          },
          {
            Icon: History,
            name: 'USD Transactions',
            link: '/cs/USDTransactions'
          },
          {
            Icon: History,
            name: 'Crypto Transactions',
            link: '/cs/cryptoTransactions'
          },
          {
            Icon: History,
            name: 'Crypto Transfers',
            link: '/cs/cryptoTransfers'
          },
          {
            Icon: History,
            name: 'Crypto Future Transactions',
            link: '/cs/cryptoFutureTransactions'
          },
          {
            Icon: History,
            name: 'Crypto Future Funding',
            link: '/cs/cryptoFutureFunding'
          },
          {
            Icon: History,
            name: 'Crypto Future Transfers',
            link: '/cs/cryptoFutureTransfers'
          },
          {
            Icon: History,
            name: 'GSS Transactions CFD',
            link: '/cs/globalSingleStockTransactions'
          },
          {
            Icon: History,
            name: 'GSS Transactions PALN',
            link: '/cs/globalSingleStockTransactionsPaln'
          },
          {
            Icon: History,
            name: 'RDN Top Up',
            link: '/cs/rdnTopupList'
          },
          {
            Icon: History,
            name: 'Pocket Transactions',
            link: '/cs/pocketTransactions'
          },
          {
            Icon: History,
            name: 'ID Stock Transactions',
            link: '/cs/indonesianSingleStockTransactions'
          },
          {
            Icon: History,
            name: 'Options Transactions',
            link: '/cs/optionsTransactions'
          }
        ]
      }
    ]
  },
  {
    Icon: AccountBalance,
    name: 'Finance',
    items: [
      {
        Icon: CalendarMonthRounded,
        name: 'Installment',
        link: '/fi/installment'
      },
      {
        Icon: HourglassBottom,
        name: 'Request Default Installment',
        link: '/fi/reqInstallment'
      },
      {
        Icon: Code,
        name: 'BAG Manual Transfers',
        link: '/fi/bagManualTransfers'
      },
      {
        Icon: Percent,
        name: 'Commission',
        link: '/fi/commission'
      },
      {
        Icon: LocalAtm,
        name: 'Cashout',
        link: '/fi/cashout'
      },
      {
        Icon: LocalAtm,
        name: 'BSC Cashout',
        link: '/fi/bscCashout'
      },
      {
        Icon: FactCheck,
        name: 'Topup',
        link: '/fi/topup'
      },
      {
        Icon: FactCheck,
        name: 'BCA',
        items: [
          {
            Icon: Wallet,
            name: 'BCA Topup',
            link: '/fi/bca/topup'
          },
          {
            Icon: Wallet,
            name: 'Bca Credit Notification',
            link: '/fi/bca/creditNotification'
          },
          {
            Icon: Wallet,
            name: 'Bca Bank Statement',
            link: '/fi/bca/bankStatement'
          }
        ]
      },
      {
        Icon: ViewInAr,
        name: 'KKI Transaction History',
        link: '/fi/kkiTransactionHistory'
      },
      {
        Icon: ViewInAr,
        name: 'Topup And Cashout',
        link: '/fi/topupAndCashout'
      },
      {
        Icon: LocalAtm,
        name: 'Rdn Cashout',
        link: '/fi/rdnCashout'
      },
      {
        Icon: History,
        name: 'Crypto Currencies',
        link: '/fi/cryptoCurrency'
      },
      // {
      //   Icon: History,
      //   name: 'Global Single Stock',
      //   link: '/fi/globalSingleStockIndex'
      // },
      {
        Icon: FactCheck,
        name: 'USD VaR',
        link: '/fi/USDVaR'
      },
      {
        Icon: AttachMoney,
        name: 'USD Yield',
        items: [
          {
            Icon: CurrencyExchange,
            name: 'Update Rate',
            link: '/fi/USDYield/updateRate'
          }
        ]
      },
      {
        Icon: History,
        name: 'Transactions',
        items: [
          {
            Icon: History,
            name: 'Gold Transactions',
            link: '/fi/goldTransactions'
          },
          {
            Icon: History,
            name: 'Gold Send Transactions',
            link: '/fi/goldSendTransactions'
          },
          {
            Icon: FactCheck,
            name: 'Fund Transactions',
            link: '/fi/fundTransactions'
          },
          {
            Icon: History,
            name: 'USD Transactions',
            link: '/fi/USDTransactions'
          },
          {
            Icon: History,
            name: 'Crypto Transactions',
            link: '/fi/cryptoTransactions'
          },
          {
            Icon: History,
            name: 'Crypto Transfers',
            link: '/fi/cryptoTransfers'
          },
          {
            Icon: History,
            name: 'Crypto Future Transactions',
            link: '/fi/cryptoFutureTransactions'
          },
          {
            Icon: History,
            name: 'Crypto Future Funding',
            link: '/fi/cryptoFutureFunding'
          },
          {
            Icon: History,
            name: 'Crypto Future Transfers',
            link: '/fi/cryptoFutureTransfers'
          },
          {
            Icon: History,
            name: 'GSS Transactions CFD',
            link: '/fi/globalSingleStockTransactions'
          },
          {
            Icon: History,
            name: 'GSS Transactions PALN',
            link: '/fi/globalSingleStockTransactionsPaln'
          },
          {
            Icon: History,
            name: 'ID Stock Transactions',
            link: '/fi/indonesianSingleStockTransactions'
          },
          {
            Icon: History,
            name: 'Options Transactions',
            link: '/fi/optionsTransactions'
          }
        ]
      }
    ]
  },
  {
    Icon: CardGiftcard,
    name: 'Voucher Management',
    items: [
      {
        Icon: CalendarMonthRounded,
        name: 'Purchase Order',
        link: '/vs/purchase'
      },
      {
        Icon: Calculate,
        name: 'Inventory',
        link: '/vs/inventory'
      },
      {
        Icon: Email,
        name: 'Send Voucher Emails',
        link: '/vs/sendEmail'
      },
      {
        Icon: Add,
        name: 'Create Purchase Order',
        link: '/vs/purchaseOrderMerchant'
      }
    ]
  },
  {
    Icon: Visibility,
    name: 'App Settings',
    items: [
      {
        Icon: Percent,
        name: 'Influencer Fees',
        link: '/as/influencerFees'
      },
      {
        Icon: Image,
        name: 'Banners',
        link: '/as/banners'
      },
      {
        Icon: Image,
        name: 'Web Banners',
        link: '/as/webBanners'
      },
      {
        Icon: Code,
        name: 'Promo Codes',
        link: '/as/promoCode'
      },
      {
        Icon: Newspaper,
        name: 'News',
        link: '/as/news'
      },
      {
        Icon: Campaign,
        name: 'Promotions',
        link: '/as/promotions'
      },
      {
        Icon: LocalOffer,
        name: 'OnGoingPromotions',
        link: '/as/onGoingPromotions'
      },
      {
        Icon: ContactSupport,
        name: 'QuestionAnswers',
        link: '/as/questionAnswers'
      },
      {
        Icon: Article,
        name: 'Tutorials',
        link: '/as/tutorials'
      },
      {
        Icon: Article,
        name: 'GoInvest Article',
        link: '/as/goinvestArticle'
      },
      {
        Icon: Article,
        name: 'Inbox Cards',
        link: '/as/inboxCard'
      }
    ]
  },
  {
    Icon: LocalAtm,
    name: 'Mutual Fund',
    items: [
      {
        Icon: Sync,
        name: 'Reconciliation',
        link: '/mf/reconciliation'
      },
      {
        Icon: Savings,
        name: 'Bank Data',
        link: '/mf/bankData'
      },
      {
        Icon: Newspaper,
        name: 'Transaction History',
        link: '/mf/transactionHistory'
      },
      {
        Icon: ContactMail,
        name: 'KTP',
        link: '/mf/sInvest'
      },
      {
        Icon: LocalAtm,
        name: 'Failed Disbursements to CB',
        link: '/mf/failedDisbursements'
      },
      {
        Icon: LocalAtm,
        name: 'Refund Wrong VA Payment',
        link: '/mf/wrongVa'
      },
      {
        Icon: LocalAtm,
        name: 'Bulk Dividend',
        link: '/mf/bulkDividend'
      }
    ]
  }
];
